import React from 'react';

interface HelloWorldProps {
  greeting: string;
}

const HelloWorld = (props: HelloWorldProps) => {
  return (
    <div>
      <h1>Hello, World!</h1>
      <p>{props.greeting}</p>
    </div>
  )
}

export default HelloWorld;