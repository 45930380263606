import ReactRailsUJS from 'react_ujs'
import path from 'path'

const componentRequireContext = import.meta.globEager('../javascripts/top-level-components/*.tsx')
ReactRailsUJS.useContext(componentRequireContext)

Object.keys(componentRequireContext).forEach((fullpath) => {
  const component = componentRequireContext[fullpath]
  const componentName = path.basename(fullpath, path.extname(fullpath))

  window[componentName] = component
})